// @ts-strict-ignore
import React from "react"

import { Form, Formik } from "formik"
import styled, { css } from "styled-components"

import { Text, TextLink, Tooltip } from "@kiwicom/orbit-components"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import {
  InformationCircle,
  NewWindow,
} from "@kiwicom/orbit-components/lib/icons"
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery"

import { EmberSelectOption } from "components/generic/ember-select"
import {
  EmberFormSelect,
  EmberRadioGroup,
} from "components/generic/formik-inputs"
import { ListTitleProps } from "components/generic/list-title"
import Layout from "components/layout"
import { RouteMap, RouteMapProps } from "components/route-map"

const InfoPanel = styled.div`
  position: relative;
  background: #ffffff;
  padding: 15px 15px;
  bottom: 0px;
  border-top: 1px solid ${(props) => props.theme.orbit.borderColorCard};
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  ${media.largeMobile(css`
    position: absolute;
    top: 60px;
    bottom: unset;
    margin: 10px;
    width: 400px;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.orbit.borderColorCard};
    border: 0;
    box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
  `)}
`

const RouteMapWrapper = styled.div`
  flex: 1;
  display: flex;
`

// Messy but can be cleaned up post timetable change on 22 October

function routeOptions(date: string): (EmberSelectOption | ListTitleProps)[] {
  if (date == "before-22-oct") {
    return [
      {
        id: "all-routes",
        title: "All Routes",
      },
      { type: "list-title", title: "Route E1" },
      {
        id: "aberdeen-to-edinburgh-via-dundee-outbound",
        title: "Dundee to Edinburgh",
      },
      {
        id: "aberdeen-to-edinburgh-via-dundee-return",
        title: "Edinburgh to Dundee",
      },
      { type: "list-title", title: "Route E3" },
      {
        id: "dundee-to-glasgow-outbound",
        title: "Dundee to Glasgow",
      },
      {
        id: "dundee-to-glasgow-return",
        title: "Glasgow to Dundee",
      },
      {
        type: "list-title",
        title: "Route E10",
      },
      {
        id: "msip-to-dundee-city-centre-return",
        title: "Dundee City Centre to Dundee East",
      },
      {
        id: "msip-to-dundee-city-centre-outbound",
        title: "Dundee East to Dundee City Centre",
      },
      {
        type: "list-title",
        title: "Route E1X (Ends 21 October)",
      },
      {
        id: "dundee-to-wallyford-via-edinburgh-outbound",
        title: "Dundee to Wallyford (via Edinburgh)",
      },
      {
        id: "dundee-to-wallyford-via-edinburgh-return",
        title: "Wallyford to Dundee (via Edinburgh)",
      },
      {
        type: "list-title",
        title: "Route E3X",
      },
      {
        id: "dundee-to-glasgow-via-perth-outbound",
        title: "Dundee & Perth to Glasgow",
      },
      {
        id: "dundee-to-glasgow-via-perth-return",
        title: "Glasgow to Dundee & Perth",
      },
    ]
  } else {
    return [
      {
        id: "all-routes",
        title: "All Routes",
      },
      { type: "list-title", title: "Route E1" },
      {
        id: "aberdeen-to-edinburgh-via-dundee-outbound",
        title: "Aberdeen to Edinburgh (via Dundee)",
      },
      {
        id: "aberdeen-to-edinburgh-via-dundee-return",
        title: "Edinburgh to Aberdeen (via Dundee)",
      },
      { type: "list-title", title: "Route E3" },
      {
        id: "dundee-to-glasgow-outbound",
        title: "Dundee to Glasgow",
      },
      {
        id: "dundee-to-glasgow-return",
        title: "Glasgow to Dundee",
      },
      {
        type: "list-title",
        title: "Route E10",
      },
      {
        id: "msip-to-dundee-city-centre-return",
        title: "Dundee City Centre to Dundee East",
      },
      {
        id: "msip-to-dundee-city-centre-outbound",
        title: "Dundee East to Dundee City Centre",
      },
      {
        type: "list-title",
        title: "Route E3X",
      },
      {
        id: "dundee-to-glasgow-via-perth-outbound",
        title: "Dundee & Perth to Glasgow",
      },
      {
        id: "dundee-to-glasgow-via-perth-return",
        title: "Glasgow to Dundee & Perth",
      },
    ]
  }
}

function getIdAndDirection(
  routeAndDirection: EmberSelectOption
): RouteMapProps["showService"] {
  let id:
    | "aberdeen-to-edinburgh-via-dundee"
    | "dundee-to-glasgow"
    | "msip-to-dundee-city-centre"
    | "dundee-to-wallyford-via-edinburgh"
    | "dundee-to-glasgow-via-perth"
  let direction: "outbound" | "return"
  if (routeAndDirection.id == "all-routes") {
    return "all"
  } else if (
    routeAndDirection.id == "aberdeen-to-edinburgh-via-dundee-outbound"
  ) {
    id = "aberdeen-to-edinburgh-via-dundee"
    direction = "outbound"
  } else if (
    routeAndDirection.id == "aberdeen-to-edinburgh-via-dundee-return"
  ) {
    id = "aberdeen-to-edinburgh-via-dundee"
    direction = "return"
  } else if (routeAndDirection.id == "dundee-to-glasgow-outbound") {
    id = "dundee-to-glasgow"
    direction = "outbound"
  } else if (routeAndDirection.id == "dundee-to-glasgow-return") {
    id = "dundee-to-glasgow"
    direction = "return"
  } else if (routeAndDirection.id == "msip-to-dundee-city-centre-outbound") {
    id = "msip-to-dundee-city-centre"
    direction = "outbound"
  } else if (routeAndDirection.id == "msip-to-dundee-city-centre-return") {
    id = "msip-to-dundee-city-centre"
    direction = "return"
  } else if (
    routeAndDirection.id == "dundee-to-wallyford-via-edinburgh-outbound"
  ) {
    id = "dundee-to-wallyford-via-edinburgh"
    direction = "outbound"
  } else if (
    routeAndDirection.id == "dundee-to-wallyford-via-edinburgh-return"
  ) {
    id = "dundee-to-wallyford-via-edinburgh"
    direction = "return"
  } else if (routeAndDirection.id == "dundee-to-glasgow-via-perth-outbound") {
    id = "dundee-to-glasgow-via-perth"
    direction = "outbound"
  } else if (routeAndDirection.id == "dundee-to-glasgow-via-perth-return") {
    id = "dundee-to-glasgow-via-perth"
    direction = "return"
  } else {
    id = null
    direction = null
  }
  return { id: id, direction: direction }
}

export default () => {
  return (
    <Layout
      title="Map"
      description="Track your bus, check out our route map and find out exactly where we stop"
      includeFooter={false}
    >
      <Formik
        initialValues={{
          date: "before-22-oct",
          routeAndDirection: routeOptions("before-22-oct")[0],
        }}
        onSubmit={() => null}
      >
        {({ values, setFieldValue }) => (
          <Form style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <RouteMapWrapper>
              <RouteMap
                showService={getIdAndDirection(
                  values.routeAndDirection as EmberSelectOption
                )}
                as_of={
                  values.date == "from-22-oct" ? "2024-10-23" : "2024-10-21"
                }
                showVehicles
                padding={100}
              />
            </RouteMapWrapper>
            <InfoPanel>
              <Stack>
                <Stack spacing="XSmall" direction="column">
                  <Stack direction="row" align="center" spacing="XXSmall">
                    <Text weight="bold">Date</Text>
                    <Tooltip
                      content={
                        <Stack spacing="XXSmall">
                          <Text>
                            We're launching a new route to Aberdeen and
                            adjusting some of our other stops from 22 October.
                          </Text>
                          <TextLink
                            external
                            iconRight={<NewWindow />}
                            href="/news/#new-aberdeen-to-edinburgh-service-and-other-improvements-22-Sep-2024"
                          >
                            Learn about the changes
                          </TextLink>
                        </Stack>
                      }
                    >
                      <InformationCircle color="info" />
                    </Tooltip>
                  </Stack>
                  <EmberRadioGroup
                    name="date"
                    direction="row"
                    options={[
                      {
                        value: "before-22-oct",
                        label: "Before 22 Oct",
                      },
                      { value: "from-22-oct", label: "From 22 Oct" },
                    ]}
                    onChange={(value) => {
                      const newRouteOptions = routeOptions(
                        value.currentTarget.value
                      ).filter((value) => value.type != "list-title")
                      const selectedRouteOption =
                        values.routeAndDirection as EmberSelectOption
                      if (
                        newRouteOptions.some(
                          (value) =>
                            "id" in value &&
                            value.id == selectedRouteOption.id &&
                            value.title != selectedRouteOption.title
                        )
                      ) {
                        setFieldValue(
                          "routeAndDirection",
                          newRouteOptions.find(
                            (value) =>
                              "id" in value &&
                              value.id == selectedRouteOption.id
                          )
                        )
                      } else if (
                        newRouteOptions.some(
                          (value) =>
                            "id" in value && value.id == selectedRouteOption.id
                        ) == false
                      ) {
                        setFieldValue(
                          "routeAndDirection",
                          routeOptions("before-22-oct")[0]
                        )
                      }
                    }}
                  />
                </Stack>
                <Stack spacing="XSmall" direction="column">
                  <Text weight="bold">Route and Direction</Text>
                  <EmberFormSelect
                    name="routeAndDirection"
                    options={routeOptions(values.date)}
                    customMaxHeightDesktop="70vh"
                    customMaxHeightMobile="90vh"
                  />
                </Stack>
              </Stack>
            </InfoPanel>
          </Form>
        )}
      </Formik>
    </Layout>
  )
}
